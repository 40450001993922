@import "~antd/dist/antd.css";

.background {
	background-color: #dfffff;
}

.person-wrapper {
	padding: 10vw;
	padding-bottom: 3vw;
}

.person-name {
	font-size: min(6em, 15vw);
}
.person-description {
	font-size: min(3em, 7vw);
}

.person-add-wrapper {
	padding: max(3vw, 10px) 0;
	/* border-width: 2px 0;
	border-color: black;
	border-style: dashed; */
}
.person-add-container {
	background-color: white;
	border-radius: max(2vw, 20px);
	padding: max(5vw, 20px);
}

.person-add-wrapper h1 {
	font-size: 2em;
}

.person-add-messageWrapper {
	margin-bottom: 24px;
	text-align: right;
}
.person-add-message-formItem {
	margin-bottom: 2px;
}
.person-add-message-mdIcon {
	width: max(1.7vw, 30px);
}

.ant-upload {
	border-radius: max(2vw, 20px) !important;
}
.person-add-message-input {
	border-radius: max(1vw, 10px) !important;
}

.person-messages {
	margin-top: max(5vw, 30px);
}

.person-messages-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: calc(-1 * max(2vw, 30px)); /* gutter size offset */
	width: auto;
}
.person-messages-masonry-grid_column {
	padding-left: max(2vw, 30px); /* gutter size */
	background-clip: padding-box;
}

.person-message {
	background-color: white;
	border-radius: max(3vw, 20px);
	margin-bottom: max(2vw, 20px);
	padding: max(3vw, 10px);
}

.person-message-content {
	font-size: 2em;
}

.person-message-content-images {
	margin: 2vw 0;
}

.person-message-avatar {
	margin-right: max(1vw, 10px);
	width: max(3vw, 30px);
	height: max(3vw, 30px);
}

.person-message-senderName {
	font-size: 1.5em;
}

.gh-link {
	color: inherit;
}

.credit-sep {
	padding: 0 10px;
}
.ant-layout-footer {
	background-color: transparent;
}

.audioPlayer {
	margin-top: 1em;
}
